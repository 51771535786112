<template>
    <b-card-code title="Persons">
        <h3> {{ this.event.Aforo_name }}</h3>
        <h5>{{ this.event.pkid_mxs_event }} -> {{ this.event.event_name }} </h5>
        <div class="d-flex justify-content-end flex-wrap">
            <b-form-group>
                <b-input-group>
                    <div class="d-flex justify-content-between">
                        <b-input-group>
                            <b-button variant="outline-primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" to="/events">
                                <!-- , params: { idEvent: data.idEvent} -->
                                <feather-icon icon="ArrowLeftCircleIcon" class="mr-0 mr-sm-50"></feather-icon>
                                Go Back
                            </b-button>
                        </b-input-group>
                    </div>
                    <b-dropdown v-if="$can('manage', 'PersonsActions')" text="Actions" variant="primary" right>
                        <b-dropdown-item v-if="$can('export', 'ExportPersons')" @click="onClickExport">
                            <feather-icon icon="DownloadIcon" /> Export Data
                        </b-dropdown-item>
                        <b-dropdown-item v-if="$can('export', 'ExportPersonsAccess')" @click="onClickExportSCA">
                            <feather-icon icon="DownloadCloudIcon" /> Export Acces Data
                        </b-dropdown-item>
                        <b-dropdown-item v-if="$can('import', 'ImportPersons')" :to="{ name: 'importfiles', params: { idEvent: this.idEvent } }">
                            <feather-icon icon="UploadIcon" /> Import File Persons
                        </b-dropdown-item>
                        <b-dropdown-item v-if="$can('delete', 'DeletePerson')" @click="onClickDeletePersons">
                            <feather-icon icon="Trash2Icon" /> Delete Persons
                        </b-dropdown-item>
                        <b-dropdown-item v-if="$can('create', 'AddPerson')" :to="{ name: 'create-person', params: { idEvent: this.idEvent } }">
                            <feather-icon icon="UserPlusIcon" /> New Person
                        </b-dropdown-item>
                    </b-dropdown>
                </b-input-group>
            </b-form-group>
        </div>
        <vue-good-table ref="table" :select-options="{ enabled: true, disableSelectInfo: true }" :columns="columns"
            :rows="rows" :search-options="{ enabled: true, externalQuery: searchTerm }"
            :pagination-options="{ enabled: true, perPage: pageLength }">
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'DocStatus'">
                    <b-badge :variant="statusVariant(props.row.DocStatus)">
                        {{ props.row.DocStatus }}
                    </b-badge>
                </span>

                <span v-else-if="props.column.field === 'details'">
                    <span>
                        <b-button variant="primary" v-if="$can('edit', 'EditPerson')"
                            :to="{ name: 'details', params: { id: props.row.pkid_accreditation_document_detail } }"
                            class="btn-icon">
                            <!-- , idEvent: data.idEvent -->
                            <feather-icon icon="FileIcon" />
                        </b-button>
                    </span>
                </span>
            </template>

            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap">
                            Showing 1 to
                        </span>
                        <b-form-select v-model="pageLength" :options="['15', '50', '100', '300', '3000']" class="mx-1"
                            @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                        <span class="text-nowrap "> of {{ props.total }} entries </span>
                    </div>
                    <div>
                        <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
                            align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                            @input="(value) => props.pageChanged({ currentPage: value })">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>


    </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
    BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton, BInputGroup, BFormCheckbox 
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import router from "@/router";
import axios from "@/libs/axios";
import XLSX from 'xlsx'

export default {
    components: {
        BCardCode,
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BButton,
        BInputGroup,
        BFormCheckbox,
    },
    data() {
        return {
            pageLength: 50,
            dir: false,
            columns: [
                { label: 'Details', field: 'details', tdClass: 'text-center' },
                { label: 'Name', field: 'Nom_titular', filterOptions: { enabled: true, placeholder: 'Search Name' } },
                { label: 'Surname1', field: 'Cognom1_titular', filterOptions: { enabled: true, placeholder: 'Search Surname1' } },
                { label: 'Surname2', field: 'Cognom2_titular', filterOptions: { enabled: true, placeholder: 'Search Surname2' } },
                { label: 'DOI', field: 'DNI', filterOptions: { enabled: true, placeholder: 'Search DOI' } },
                { label: 'Car Plate', field: 'Matricula', filterOptions: { enabled: true, placeholder: 'Search Car Plate' } },
                { label: 'Barcode (Wristband)', field: 'Cod_Barras', filterOptions: { enabled: true, placeholder: 'Search Barcode (Wristband)' } },
                { label: 'SCA Acces', field: 'AccesSCA', filterOptions: { enabled: true, placeholder: 'Search SCA Acces' } },
                { label: 'Company', field: 'Empresa_nom', filterOptions: { enabled: true, placeholder: 'Search Company' } },
                { label: 'DocStatus', field: 'DocStatus', filterOptions: { enabled: true, placeholder: 'Search DocStatus' } },
                { label: 'DocStatusSCA', field: 'DocStatusSCA', filterOptions: { enabled: true, placeholder: 'Search DocStatusSCA' } },
                { label: 'ID_Doc', field: 'pkid_accreditation_document_detail', filterOptions: { enabled: true, placeholder: 'Search ID' } },
                { label: 'ID_Accred', field: 'fkid_accreditation_document', filterOptions: { enabled: true, placeholder: 'Search ID' } },
            ],
            rows: [],
            sca: [],
            eventName: '',
            searchTerm: '',
            event: [],
            idEvent: router.currentRoute.params.idEvent,
            selectedRows: [],
        }
    },
    computed: {
        statusVariant() {
            const statusColor = {
                OK: 'light-success',
                OK_Exit: 'light-warning',
                'OK_Exit+': 'light-warning',
                OK_entered: 'light-info',
                ERROR: 'light-danger',
                'INCORRECTE No es vàlid realitzar moviment de sortida': 'light-danger',
                REPETIT: 'light-danger',
                'Data venciment fora termini': 'light-danger',
                'Data fi validesa fora termini': 'light-danger',
                'Data caducitat fora termini': 'light-danger',
                'No existeix document en detall event': 'light-danger',
                'A BAIXA Entrada a Baixa': 'light-danger',
                'ERROR DNI ACREDITACIO duplicat': 'light-danger',
                'ACREDITACIO Anulada': 'light-danger',
                'DENEGEAT ACREDITACIO': 'light-danger',
                'ELIMINAT - NO PDA': 'light-primary'
            }
            return status => statusColor[status]
        },
        direction() {
            if (store.state.appConfig.isRTL) {

                this.dir = true
                return this.dir
            }

            this.dir = false
            return this.dir
        },
    },
    methods: {
        onClickExport() {
            const ws = XLSX.utils.json_to_sheet(this.rows)
            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
            XLSX.writeFile(wb, 'Persons_' + this.eventName + '.xlsx')
        },
        onClickExportSCA() {
            const ws = XLSX.utils.json_to_sheet(this.sca)
            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
            XLSX.writeFile(wb, 'AccesRegistry_' + this.eventName + '.xlsx')
        },
        async onClickDeletePersons() {
            const selectedRows = this.$refs['table'].selectedRows
            let error = false
            selectedRows.forEach(element => {
                if (element.DocStatusSCA !== null && element.DocStatusSCA !== "") {
                    error = true
                }
            });

            if (selectedRows.length <= 0) {
                const variant = "danger";
                this.$bvToast.toast("No records selected to delete", {
                    title: `` + "Error",
                    variant,
                    solid: true,
                });
            } else if (!error) {
                const ids = selectedRows.map(row => row.pkid_accreditation_document_detail).toString()
                await axios
                    .delete("/accred-doc-detailed?ids=" + ids)
                    .then(async () => {
                        const variant = "success";
                        this.$bvToast.toast("Person/s deleted successfully", {
                                    title: "Success",
                                    variant,
                                    solid: true,
                                });
                        await axios
                            .get("/event?pkid_mxs_event=" + router.currentRoute.params.idEvent)
                            .then((results) => {
                                this.event = results.data[0];
                                this.eventName = results.data[0].event_name
                                this.eventName = this.eventName.trim()
                            })
                            .catch((error) => {
                                const variant = "danger";
                                this.$bvToast.toast("Failed to connect", {
                                    title: `` + error,
                                    variant,
                                    solid: true,
                                });
                            })

                        await axios
                            .get("/accred-doc-detailed?fkid_mxs_event=" + router.currentRoute.params.idEvent)
                            .then((results) => {
                                this.rows = results.data;

                            })
                            .catch((error) => {
                                const variant = "danger";
                                this.$bvToast.toast("Failed to connect", {
                                    title: `` + error,
                                    variant,
                                    solid: true,
                                });
                            })

                        await axios
                            .get("/accred-sca-registry?fkid_mxs_event=" + router.currentRoute.params.idEvent)
                            .then((results) => {
                                this.sca = results.data;
                            })
                            .catch((error) => {
                                const variant = "danger";
                                this.$bvToast.toast("Failed to connect", {
                                    title: `` + error,
                                    variant,
                                    solid: true,
                                });
                            })
                    })
                    .catch((error) => {
                        const variant = "danger";
                        this.$bvToast.toast("Failed to connect", {
                            title: `` + error,
                            variant,
                            solid: true,
                        });
                    })
            } else {
                const variant = "danger";
                this.$bvToast.toast("Check that DocStatusSCA is null", {
                    title: `` + "Could not delete selected records",
                    variant,
                    solid: true,
                });
            }
        },
    },
    async created() {

        await axios
            .get("/event?pkid_mxs_event=" + router.currentRoute.params.idEvent)
            .then((results) => {
                this.event = results.data[0];
                this.eventName = results.data[0].event_name
                this.eventName = this.eventName.trim()
            })
            .catch((error) => {
                const variant = "danger";
                this.$bvToast.toast("Failed to connect", {
                    title: `` + error,
                    variant,
                    solid: true,
                });
            })

        await axios
            .get("/accred-doc-detailed?fkid_mxs_event=" + router.currentRoute.params.idEvent)
            .then((results) => {
                this.rows = results.data;

            })
            .catch((error) => {
                const variant = "danger";
                this.$bvToast.toast("Failed to connect", {
                    title: `` + error,
                    variant,
                    solid: true,
                });
            })

        await axios
            .get("/accred-sca-registry?fkid_mxs_event=" + router.currentRoute.params.idEvent)
            .then((results) => {
                this.sca = results.data;
            })
            .catch((error) => {
                const variant = "danger";
                this.$bvToast.toast("Failed to connect", {
                    title: `` + error,
                    variant,
                    solid: true,
                });
            })
    },
    mounted() {
        if (localStorage.getItem("accessToken") === null) {
            this.$router.go(0)
        }
    },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
// .vgt-checkbox-col input[type="checkbox" i] {
    
// }
</style>